import * as modalDialogs from "vue-modal-dialogs";
import alertModal from "./alert-modal.vue";

Vue.use(modalDialogs);
const alertModalDialog = modalDialogs.create({
    component: alertModal,
    wrapper:"alert"
});
const vm = new Vue({
    el: "#alertModal",
    template: "<dialogs-wrapper name='alert'/>"
});

/**
 * 使用bootstrip modal實做的alert
 * @param {any} msg
 * @param {any} style
 * @param {any} title
 */
window.alert = async function (msg, style, title, afterCloseMethod) {
    await alertModalDialog({
        title: title, content: msg, alertStyle: style
    });
    if (afterCloseMethod)
        afterCloseMethod();
}

window.messageBox = async function (setting) {
    const result = await alertModalDialog({
        title: setting.title,
        content: setting.msg,
        alertStyle: setting.style,
        buttons: setting.buttons,
        footerText: setting.footerText
    });
    if (setting.afterCloseMethod)
        afterCloseMethod();
    return result;
}