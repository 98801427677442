<template>
    <div class="alert-modal-background" @click.self="cancel">
        <div class="modal vue-modal" @click.self="cancel">
            <div class="modal-dialog">
                <div :class="panelClass">
                    <div class="panel-heading">
                        <label>{{styledTitle}}</label>
                        <button class="close" @click="cancel">&times;</button>
                    </div>
                    <div class="panel-body">{{content}}</div>
                    <div :class="panelFooterClass" v-if="styledButtons.length > 0">
                        <template v-for="(button,key) in styledButtons">
                            <button :key=key
                                    :class="button.cssClass"
                                    @click="button.clickMehtod">{{button.name}}</button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
            },
            content: String,
            alertStyle: {
                type: String,
                default:"warning"
            },
            footerText: {
                type: String,
                default:"right"
            },
            buttons: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        computed: {
            styledTitle() {
                return this.title ||
                    {
                        info: "訊息",
                        warning: "警告",
                        danger: "嚴重警告",
                        success: "成功",
                        primary: "注意"
                }[this.alertStyle];
            },
            panelClass() {
                let cssClass = {
                    panel: true
                };
                cssClass[`panel-${this.alertStyle}`] = true;
                return cssClass;
            },
            panelFooterClass() {
                let cssClass = {
                    "panel-footer": true,
                };
                cssClass[`text-${this.footerText}`] = true;
                return cssClass;
            },
            styledButtons() {
                const vm = this;
                return this.buttons.map(item =>
                {
                    return {
                        name: item.name,
                        cssClass: (() => {
                            let cssClass = {
                                btn: true,
                                "alert-button":true
                            };
                            const style = item.style || "default";
                            cssClass[`btn-${style}`] = true;
                            return cssClass;
                        })(),
                        clickMehtod() {
                            vm.$close(item.value);
                            if (item.method)
                                item.method();
                        }
                    };
                })
            }
        },
        methods: {
            cancel() {
                this.$close(false);
            }
        }
    }
</script>
<style>
    .alert-modal-background {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.33);
        z-index:2000;
        padding-top: 50px;
    }
    .vue-modal {
        display: block;
        position: relative;
    }
    .alert-button{
        margin-left:5px;
    }
</style>