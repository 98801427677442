var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert-modal-background",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.cancel($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal vue-modal",
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.cancel($event)
            }
          }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { class: _vm.panelClass }, [
              _c("div", { staticClass: "panel-heading" }, [
                _c("label", [_vm._v(_vm._s(_vm.styledTitle))]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "close", on: { click: _vm.cancel } },
                  [_vm._v("×")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel-body" }, [
                _vm._v(_vm._s(_vm.content))
              ]),
              _vm._v(" "),
              _vm.styledButtons.length > 0
                ? _c(
                    "div",
                    { class: _vm.panelFooterClass },
                    [
                      _vm._l(_vm.styledButtons, function(button, key) {
                        return [
                          _c(
                            "button",
                            {
                              key: key,
                              class: button.cssClass,
                              on: { click: button.clickMehtod }
                            },
                            [_vm._v(_vm._s(button.name))]
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }